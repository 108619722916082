import { Product } from '../../interfaces/products/shop'
interface SaveShopStatePayload {
  currentPage: number
  selectedCategory: number
  sort: Record<string, 'ASC' | 'DESC'> | null
  filters: any
  products: Product[]
  scrollPosition: number
  isDrawerOpen: boolean
  currentQuery: any
  currentPath: string
  timestamp: number
}

export const SAVE_SHOP_STATE = (state: SaveShopStatePayload) => ({
  type: 'SAVE_SHOP_STATE',
  payload: state,
})

export const RESTORE_SHOP_STATE = () => ({
  type: 'RESTORE_SHOP_STATE',
})
